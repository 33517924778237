// @flow strict
import React from "react";
import { graphql, Link, navigate } from "gatsby";
import Sidebar from "../components/Sidebar";
import Layout from "../components/Layout";
import Feed from "../components/Feed";
import Page from "../components/Page";
import type { AllMarkdownRemark } from "../types";

type Props = {
  data: AllMarkdownRemark,
};

const ReasonableInvestorTemplate = ({ data }: Props) => {
  const title = "The Reasonable Investor";
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout title={title} description={title}>
      <Page title={title}>
        <div className="page__body">
          <p>
            Bem vindo à sub-página <i>The Reasonable Investor</i>!
          </p>
          <p>
            O objetivo é bastante simples: partilhar os meus pensamentos sobre
            finanças e investimentos. Acredito que tudo o que aprendo possa ser
            útil para mim, para amigos ou para ti.
          </p>
          <p>
            Espero que o conteúdo que for colocando, embora não seja qualquer
            tipo de aconselhamento, ajude a tomar melhores decisões financeiras
            ou a obter outras perspectivas.
          </p>
          <p>
            Tenciono melhorar esta página consoante as necessidades surgirem, e
            conto com todo o apoio e feedback possível.
          </p>
          <p>
            <a
              href="mailto:the.reasonable.investor.pt@gmail.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>Contacta-me!</b>
            </a>
          </p>
        </div>
        <h2 className="page__subtitle">Subscrever Novos Artigos</h2>
        <p>Receber um email sempre que um novo artigo for publicado:</p>
        <p>
          <button
            onClick={() => {
              navigate("/pages/the-reasonable-subscriber");
            }}
          >
            <strong>Subscrever</strong>
          </button>
        </p>
        <h2 className="page__subtitle">Últimas Publicações</h2>
        <Feed edges={edges} />
        <Link to="/tag/the-reasonable-investor/">
          <h4>
            <b>Ver todas as restantes publicações</b>
          </h4>
        </Link>
      </Page>
      <Sidebar />
    </Layout>
  );
};

export const query = graphql`
  query GuideQuery {
    allMarkdownRemark(
      limit: 5
      filter: {
        frontmatter: {
          template: { eq: "post" }
          draft: { ne: true }
          category: { in: ["finance", "investment"] }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;

export default ReasonableInvestorTemplate;
